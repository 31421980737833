import { api, apiUrl } from "./api.config";

const resource = "bots";

const botApi = {
    getList: async () => {
        return await api.get(`${resource}`).then((response) => response.data);
    },
    getOneByName: async (botName) => {
        return await api
            .get(`${resource}/${botName}`)
            .then((response) => response.data);
    },
    // update a bot with botInfo => { firstName, lastName, affiliation, email, username, password }
    update: async (botName, botInfo) => {
        return await api
            .put(`${resource}/${botName}`, botInfo)
            .then((response) => response.data);
    },
    execBot: async (botID, name, runtimeEnv, botPath) => {
        return api()
            .post (`${resource}/${botID}`, {
                name: name,
                runtimeEnv: runtimeEnv,
                botPath: botPath,
            })
            .then((response) => response.data);
    },
};

export default botApi;
