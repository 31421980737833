import React from "react";
import {
    Button,
} from "@mui/material";
import {
    List,
    Datagrid,
    PasswordInput,
    TextField,
    DateField,
    EmailField,
    BooleanInput,
    Create,
    Edit,
    EditButton,
    SimpleForm,
    TextInput,
    useRecordContext
} from "react-admin";
import botApi from "../../api/bot"

const ExecuteBotButton = () => {
    const record = useRecordContext();

    const handleClick = () => {
        console.log(record.name)
        botApi.execBot(record.id, record.name, record.runtimeEnv, record.path)
    };

    return (
        <Button onClick={handleClick}>
            Execute Bot
        </Button>
    );
};


export const BotList = (props) => {
    return (
        <List {...props} bulkActionButtons={false} title={"Bots"}>
            <Datagrid >
                <ExecuteBotButton />
                <TextField label="Name" source="name" />
                <TextField label="Path" source="path" />
                <TextField label="Env" source="runtimeEnv" />
                <TextField label="Trigger" source="trigger" />
                <EditButton> Edit Bot </EditButton>
            </Datagrid>
        </List>
    );
};

export const BotEdit = (props) => {
    return (
    <Edit {...props}>
        <SimpleForm>
            <TextInput label="Name" source="name" />
            <TextInput label="Bot Path" source="path" />
            <TextInput label="Env" source="runtimeEnv" />
            <TextInput label="Trigger" source="trigger" />
            <BooleanInput label="Edit Content" source="flags[0]" />
            <BooleanInput label="Edit Labels" source="flags[1]" />
            <BooleanInput label="Ban Users" source="flags[2]" />
            <BooleanInput label="No Comments" source="flags[3]" />
            <BooleanInput label="No Labels" source="flags[4]" />
            <BooleanInput label="No Rating" source="flags[5]" />
        </SimpleForm>
    </Edit>)
    };
