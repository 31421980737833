import { AppBar } from 'react-admin';
import { Typography, Button, Box} from '@mui/material';
import interApi from "../../api/interact"

const exportRep = () => {
    interApi.exportRep();
};
const importRep = () => {
    interApi.importRep();
};

export const AdminAppBar = () => {
    return(
        <AppBar color="primary">
            <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Box>
                    <Typography>
                        Admin Dashboard
                    </Typography>
                </Box>
                <Box sx={{display: 'flex', gap: 1}}>
                    <Button 
                        variant="contained"
                        color="secondary"
                        href="/"
                    >
                        Close Dashboard
                    </Button>
                    <Button 
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            exportRep()
                        }}
                    >
                        Export Reputation
                    </Button>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            importRep()
                        }}
                    >
                        Import Reputation
                    </Button>
                </Box>
            </Box>
        </AppBar>
    )
}