import { fetchUtils } from "react-admin";
const apiUrl = "https://vinegar.cs.umd.edu:8080/v1";
const userInfo = JSON.parse(localStorage.getItem("userInfo"))


const httpClient = (url, options = {}) => {
    options.headers = new Headers({ Accept: 'application/json' });
    // Add the Authorization header with the token
    options.headers.set('Authorization', `Bearer ${userInfo.token}`);
    return fetchUtils.fetchJson(url, options);
  };

// used by react-api to perform actions on backend
const dataProvider = {
    getList: async (resource) => 
        httpClient(`${apiUrl}/${resource}/admin/list`).then(({ json }) => ({
            data: json,
            total: 20,
        })),
    
    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: json,
        })),

    update: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: "PUT",
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json })),

    create: (resource, params) =>
        httpClient(`${apiUrl}/${resource}`, {
            method: "POST",
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({
            data: { ...params.data, id: json.id },
        })),

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: "DELETE",
        }).then(({ json }) => ({ data: json })),
};

export default dataProvider;
