import {Admin, Resource, Toolbar, AppBar} from "react-admin";
import {UserList, UserCreate, UserEdit} from "./admin-ui/user";
import {BotList, BotEdit} from "./admin-ui/bot";
import interApi from "../api/interact"
import AdminLayout from "./admin-ui/Layout"
import {
    Button,
    Box,
} from "@mui/material";
import dataProvider from "./admin-ui/dataprovider"
// import jsonServerProvider from "ra-data-json-server";

// test data from json place holder site
// const dataProvider = jsonServerProvider("https://jsonplaceholder.typicode.com");

const AdminDashboard = () => {

    const exportRep = () => {
        interApi.exportRep();
    };
    const importRep = () => {
        interApi.importRep();
    };

    return (
        <div className="adminDashboard">
            <Admin layout={AdminLayout} dataProvider={dataProvider} title={"Symposium"}>
                <Resource name="bots" list={BotList} edit={BotEdit}/>
                <Resource name="users" list={UserList} edit={UserEdit} create={UserCreate} />
            </Admin>
        </div>
    );
};

export default AdminDashboard;
