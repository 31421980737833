import { api, apiUrl } from "./api.config";

const resource = "interact";

const interApi = {
    exportRep: async () => {
        return api()
            .post(`rep/exportReputation/`)
            .then((response) => response.data);
    },
    importRep: async () => {
        return api()
            .post(`rep/importReputation/`)
            .then((response) => response.data);
    },
    getRep: async(userId) => {
        return api()
            .post(`rep/getUserReputation/${userId}`)
            .then((response) => response.data);
    }
};

export default interApi;