import { useState } from "react";
import clsx from "clsx";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Snackbar from "@mui/material/Snackbar";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import Alert from "@mui/material/Alert";
import PersonIcon from "@mui/icons-material/Person";
import TextField from "@mui/material/TextField";
import {SecondaryListItems} from "../components/listItems";
import Copyright from "../components/Copyright";
import {useUserProfileStyles} from "../styles/userProfileStyles.js"
import { useAuth } from "../useAuth";
import Switch from '@material-ui/core/Switch';

import { Add, Groups } from "@mui/icons-material";
import groupApi from "../api/group"


const NewGroupView = () => {
    const classes = useUserProfileStyles();
    const { user } = useAuth();
    const [groupName, setGroupName] = useState("");
    const [allCanAdd, setAllCanAdd] = useState(false);
    const [membersVisible, setMembersVisible] = useState(false);
    const [memberList, setMemberList] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);

    const handleSubmit = async(event) => {
        
        event.preventDefault();

        setIsSubmitting(true);
        try {
            await groupApi.createGroup(groupName, user.id, memberList, membersVisible, allCanAdd);
            setSnackbarOpen(true);
            setGroupName("");
            setMemberList("");
            setAllCanAdd(false);
            setMembersVisible(false);
        } catch (err) {
            console.error(err);
        }
        setIsSubmitting(false);
    }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleNameChange = (text) => {
        setGroupName(text.target.value);
    };

    const handleMemberListChange = (text) => {
        setMemberList(text.target.value);
    };

    const handleAllCanAddChange = (event) => {
        setAllCanAdd(event.target.checked);
    };
    
    const handleMembersVisibleChange = (event) => {
        setMembersVisible(event.target.checked);
    };

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, classes.appBarShift)}
            >
                <Toolbar className={classes.toolbar}>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        User Profile
                    </Typography>
                    <Button variant="link" color="inherit" startIcon={<PersonIcon />} href="/">
                        {user.firstName}
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper),
                }}
            >
                <div className={classes.colloquium}>
                    <Link href={"/"} underline="hover">
                        Colloquium
                    </Link>
                </div>
                <Divider />
                <SecondaryListItems/>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    {/* Profile */}
                    <h2>Create a New Group</h2>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                        <TextField 
                            variant="outlined" 
                            fullWidth={true} 
                            InputLabelProps={{ shrink: true }} 
                            label="Group Name" 
                            onChange={handleNameChange} 
                            value={groupName} 
                            style={{ marginBottom: '16px'}}>

                        </TextField>
                        <TextField 
                            variant="outlined" 
                            fullWidth={true} 
                            InputLabelProps={{ shrink: true }} 
                            label="Enter group member email addresses" 
                            onChange={handleMemberListChange} 
                            value={memberList} >

                        </TextField>
                        <div>
                            <span>Allow all members to invite new group members: </span>
                                <Switch
                                    checked={allCanAdd}
                                    onChange={handleAllCanAddChange}
                                    name="allCanAdd"
                                    color = "primary"
                                />
                        </div>
                        <div>
                            <span>Allow all members to see identities of other members: </span>
                                <Switch
                                    checked={membersVisible}
                                    onChange={handleMembersVisibleChange}
                                    name="membersVisible"
                                    color = "primary"
                                />
                        </div>
                        <Button color="primary" variant="contained" fullWidth={false} disabled={groupName === ""} onClick={handleSubmit}>
                            Create
                        </Button>
                    </Box>
                    <Snackbar
                        open={snackbarOpen}
                        autoHideDuration={5000}
                        onClose={handleCloseSnackbar}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="success">
                            Group created successfully
                        </Alert>
                    </Snackbar>
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </div>
    );
};

export default NewGroupView;
