import { useState } from "react";
import clsx from "clsx";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import userApi from "../api/user";
import Link from "@mui/material/Link";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Container from "@mui/material/Container";
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableHead
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import { useAsync } from "react-async";
import TextField from "@mui/material/TextField";
import {SecondaryListItems} from "../components/listItems";
import Copyright from "../components/Copyright";
import {useUserProfileStyles} from "../styles/userProfileStyles.js"
import { useAuth } from "../useAuth"
import { Add, Groups } from "@mui/icons-material";



const GroupsView = () => {
    const classes = useUserProfileStyles();
    const { user } = useAuth();

    const getUserGroups = async () => {
        try {
            const data = await userApi.getUserGroups(user.id);
            return data;
        } catch (error) {
            console.error(error);
        }
    }
    
    const GroupTable = () => {
        
        const { data, error } = useAsync({ promiseFn: getUserGroups});
        if (error) return error.message;
        if (data) return (
            <Table>
                <TableBody>
                    {data.map(group => (
                    <TableRow>
                        {group.name}
                    </TableRow>
                    ))}
                </TableBody>
            </Table>
        );
        return(<div>Groups Loading...</div>);
    }

    return (
        <div className={classes.root}>
            <CssBaseline />
            <AppBar
                position="absolute"
                className={clsx(classes.appBar, classes.appBarShift)}
            >
                <Toolbar className={classes.toolbar}>
                    <Typography
                        component="h1"
                        variant="h6"
                        color="inherit"
                        noWrap
                        className={classes.title}
                    >
                        User Profile
                    </Typography>
                    <Button variant="link" color="inherit" startIcon={<PersonIcon />} href="/">
                        {user.firstName}
                    </Button>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                classes={{
                    paper: clsx(classes.drawerPaper),
                }}
            >
                <div className={classes.colloquium}>
                    <Link href={"/"} underline="hover">
                        Colloquium
                    </Link>
                </div>
                <Divider />
                <SecondaryListItems/>
            </Drawer>
            <main className={classes.content}>
                <div className={classes.appBarSpacer} />
                <Container maxWidth="lg" className={classes.container}>
                    {/* Profile */}
                    <Button
                        variant="contained"
                        color="primary"
                        startIcon={<Add/>}
                        href={"/newgroup"}
                    >
                        Create New Group
                    </Button>
                    <h2>My Groups</h2>
                    {<GroupTable/>}
                    <Box pt={4}>
                        <Copyright />
                    </Box>
                </Container>
            </main>
        </div>
    );
};

export default GroupsView;
