import { api } from "./api.config";

const resource = "group";

const groupApi = {
  createGroup: async (name, creatorId, members, membersVisible, allCanAdd) => {
    return api()
      .post(`${resource}`, {
        name: name,
        creatorId: creatorId,
        members: members,
        membersVisible: membersVisible,
        allCanAdd: allCanAdd,
      })
      .then((response) => response.data);
  },
  getGroup: async (groupId) => {
    return api().get(`${resource}/${groupId}`).then((response) => response.data);
  },
  addUserToGroup: async (userId, groupId) => {
    return api()
      .post(`${resource}/addUser`, {
        userId: userId,
        groupId: groupId,
      })
      .then((response) => response.data);
  },
  deleteUserFromGroup: async (userId, groupId) => {
    return api()
      .post(`${resource}/deleteUser`, {
        userId: userId,
        groupId: groupId,
      })
      .then((response) => response.data);
  },
  setMembersVisible: async (groupId, visible) => {
    return api()
      .put(`${resource}/${groupId}/membersVisible`, {
        visible: visible,
      })
      .then((response) => response.data);
  },
  setAllCanAdd: async (groupId, allCanAdd) => {
    return api()
      .put(`${resource}/${groupId}/allCanAdd`, {
        allCanAdd: allCanAdd,
      })
      .then((response) => response.data);
  },
};

export default groupApi;
