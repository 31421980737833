import { useLocation, Redirect, Route } from "react-router-dom";
import { useAuth } from "../useAuth";
import ErrorMessage from "../components/ErrorMessage.js";

// make sure auth is set before rendering a page
const AdminRoute = ({ component: Component, ...restOfProps }) => {
    const auth = useAuth();
    const errMsg = 'Not Administrator Privileges'
    return (  
        <Route
            {...restOfProps}
            render={(props) =>
                auth.user && auth.admin ? (
                    <Component {...props} />
                ) : (
                    <ErrorMessage message={errMsg} />
                )
            }
        />
    );
};

export default AdminRoute;
